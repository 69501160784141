// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
import "semantic-ui-css/semantic.css";
import { Menu, Image, Dropdown, DropdownDivider } from "semantic-ui-react";

import {
  isAdmin,
  isAuthenticated,
  isRestAPIUser,
  isWsAPIUser,
  isBasedEventAPIUser,
  logout,
  getLoginRedirectUrl,
} from "services/self";

import { cognitoDomain, cognitoClientId } from "../services/api";

// mobx
import { observer } from "mobx-react";

// components
import MenuLink from "components/MenuLink";
import { store } from "services/state";

function getCognitoUrl(type) {
  const redirectUri = getLoginRedirectUrl();
  return `${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`;
}

export const NavBar = observer(
  class NavBar extends React.Component {
    render() {
      const email = store.user && store.user.email;
      return (
        <Menu
          inverted
          borderless
          attached
          style={{ flex: "0 0 auto", fontSize: "16px" }}
          stackable
        >
          <MenuLink to="/">
            <Image
              width="66"
              height="66"
              src="/custom-content/nav-logo.png"
              style={{ paddingRight: "10px" }}
            />
            API Portal
          </MenuLink>

          {isRestAPIUser() && (
            <NavLink
              to="/apis"
              className="item link"
              activeClassName="active-link"
            >
              REST APIs
            </NavLink>
          )}
          {isWsAPIUser() && (
            <NavLink
              to="/websocket-apis"
              className="item link"
              activeClassName="active-link"
            >
              WebSocket APIs
            </NavLink>
          )}
          {isBasedEventAPIUser() && (
            <NavLink
              to="/event-based-apis"
              className="item link"
              activeClassName="active-link"
            >
              Event Based APIs
            </NavLink>
          )}
          {(isRestAPIUser() || isWsAPIUser()) && (
            <NavLink
              to="/endpoint-security"
              className="item link"
              activeClassName="active-link"
            >
              Endpoint Security
            </NavLink>
          )}

          <Menu.Menu position="right">
            {isAuthenticated() && (
              <NavLink
                to="/developer-tools"
                className="item link"
                activeClassName="active-link"
              >
                Developer Tools
              </NavLink>
            )}
            {isAuthenticated() ? (
              <>
                <Dropdown item icon="user" simple>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="custom-dropdown-item"
                      style={{
                        color: "black",
                        font: "bold",
                        cursor: "default",
                      }}
                      onClick={(e) => e.preventDefault()}
                    >
                      {email && <span>{email}</span>}
                    </Dropdown.Item>
                    <DropdownDivider />
                    {isAdmin() && (
                      <Dropdown.Item as={NavLink} to="/admin/apis">
                        Admin Panel
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item onClick={logout}>Sign Out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <MenuLink to={getCognitoUrl("login")}>Sign In</MenuLink>
                <MenuLink to={getCognitoUrl("signup")}>Register</MenuLink>
              </>
            )}
          </Menu.Menu>
        </Menu>
      );
    }
  }
);

export default NavBar;
