import React, { useEffect, useState } from "react";
import { Form, Modal, Header, Message, Confirm } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { fetchDropdownOptions } from "services/self-service";

function EndPointDetailsForm({
  actionsModal,
  setActionsModal,
  editedEndpoints,
  setEditedEndpoints,
  apiType,
  existingEndpoints,
  selectedEndpoint,
  userAction,
  generateEndpointName,
}) {
  const {
    register,
    unregister,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
  } = useForm({ defaultValues: selectedEndpoint });
  const [integrationType, setIntegrationType] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState({
    httpMethods: [],
    integrationTypes: [],
    productCodes: [],
  });
  const [currentEndpoint, setCurrentEndpoint] = useState({});
  const [confirmBox, setConfirmBox] = useState(false);

  const LAMBDA_INVOCATION_REGEX =
    /^arn:aws:apigateway:[a-z0-9-]+:lambda:path\/[0-9]{4}-[0-9]{2}-[0-9]{2}\/functions\/arn:aws:lambda:[a-z0-9-]+:[0-9]{12}:function:[a-zA-Z0-9-_:]+\/invocations$/;
  const HTTPS_REGEX = /^https:\/\/[a-zA-Z0-9.:/{}-]+[a-zA-Z0-9}]$/;

  useEffect(() => {
    async function fetchOptions() {
      const dropdownOptions = await fetchDropdownOptions(apiType);
      if (!dropdownOptions.error) {
        setDropdownOptions(dropdownOptions.data);
      }
    }
    if (userAction !== "view") fetchOptions();
  }, [apiType, userAction]);

  useEffect(() => {
    Object.keys(selectedEndpoint).map((key) =>
      setValue(key, selectedEndpoint[key])
    );
    setCurrentEndpoint(selectedEndpoint);
    setIntegrationType(selectedEndpoint.integrationType);
    if (apiType === "websocket") {
      setIntegrationType("https-private");
    }
  }, [selectedEndpoint, apiType, setValue]);

  if (apiType === "websocket") {
    setValue("integrationType", "https-private");
    setValue(
      "specification.version",
      currentEndpoint?.specification?.version || "v1.0.0"
    );
  }

  if (apiType === "rest" && userAction === "create") {
    setValue("resourceVersion", currentEndpoint.resourceVersion || "v1");
  }

  useEffect(() => {
    if (getValues("integrationType") !== "lambda") {
      if (apiType === "rest") {
        unregister([
          "backendDetails.requestTemplate.name",
          "backendDetails.requestTemplate.value",
        ]);
      }
      unregister([
        "backendDetails.responseTemplate.name",
        "backendDetails.responseTemplate.value",
      ]);
    } else {
      register("backendDetails.requestTemplate.name", {
        required: "Please select the request template name",
      });
      if (apiType === "rest") {
        register("backendDetails.responseTemplate.name", {
          required: "Please select the response template name",
        });
      }
    }
    async function validateUri(uri) {
      await trigger("backendDetails.uri");
    }
    validateUri(getValues("backendDetails.uri"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationType, unregister]);

  const convertOptions = (options) => {
    return options.map((option, index) => ({
      key: index,
      text: option,
      value: option,
    }));
  };

  const validateResourcePath = (value) => {
    if (userAction === "edit" || apiType === "websocket") return false;
    if (editedEndpoints.concat(existingEndpoints).length === 0) {
      return false;
    } else if (/^v[1-9]$/.test(value)) {
      return editedEndpoints
        .filter((endpoint) => !_.isEqual(endpoint, selectedEndpoint))
        .concat(existingEndpoints)
        .some(
          (endpoint) =>
            endpoint.resourceVersion === value &&
            endpoint.resourcePath === getValues("resourcePath") &&
            endpoint.backendDetails.httpMethod ===
              getValues("backendDetails.httpMethod")
        );
    } else {
      return editedEndpoints
        .filter((endpoint) => !_.isEqual(endpoint, selectedEndpoint))
        .concat(existingEndpoints)
        .some(
          (endpoint) =>
            endpoint.resourcePath === value &&
            endpoint.resourceVersion === getValues("resourceVersion") &&
            endpoint.backendDetails.httpMethod ===
              getValues("backendDetails.httpMethod")
        );
    }
  };

  const validateHttpMethod = (value) => {
    if (userAction === "edit" || apiType === "websocket") return false;
    if (editedEndpoints.concat(existingEndpoints).length === 0) {
      return false;
    } else {
      return editedEndpoints
        .filter((endpoint) => !_.isEqual(endpoint, selectedEndpoint))
        .concat(existingEndpoints)
        .some(
          (endpoint) =>
            endpoint.backendDetails.httpMethod === value &&
            endpoint.resourceVersion === getValues("resourceVersion") &&
            endpoint.resourcePath === getValues("resourcePath")
        );
    }
  };

  const validateAction = (value) => {
    if (userAction === "edit") return false;
    return editedEndpoints
      .filter((endpoint) => !_.isEqual(endpoint, selectedEndpoint))
      .concat(existingEndpoints)
      .some((endpoint) => endpoint.action === value);
  };

  const validateUri = (uri) => {
    if (_.isEmpty(uri)) return true;
    if (/(lambda)/i.test(integrationType)) {
      return LAMBDA_INVOCATION_REGEX.test(uri);
    } else if (/https/.test(integrationType)) {
      return HTTPS_REGEX.test(uri);
    } else return true;
  };

  const validateJSON = (payload) => {
    try {
      if (_.isEmpty(payload)) return true;
      JSON.parse(payload);
    } catch (e) {
      return false;
    }
    return true;
  };

  const changeInput = async (input) => {
    const name = input.name;
    const value = Array.isArray(input.value)
      ? input.value.join(",")
      : input.value.trim(); //We're converting array of Product codes to comma separated string
    if (name === "integrationType") {
      setIntegrationType(value);
    }
    setValue(name, value);
    await trigger(name);
    setCurrentEndpoint(getValues());
  };

  const changeResourcePath = async (input) => {
    const name = input.name;
    const value = input.value;
    setValue(name, value);
    setCurrentEndpoint(getValues());
    await trigger(name);
    await trigger([
      "resourcePath",
      "resourceVersion",
      "backendDetails.httpMethod",
    ]);
  };

  const handleHttpMethod = async (input) => {
    const name = input.name;
    const value = input.value;
    setValue(name, value);
    await trigger([
      "resourcePath",
      "resourceVersion",
      "backendDetails.httpMethod",
    ]);
    setCurrentEndpoint(getValues());
  };

  const checkIfSame = (endpoint1, endpoint2) => {
    return generateEndpointName(endpoint1) === generateEndpointName(endpoint2);
  };

  const onSubmit = () => {
    let updatedEditedEndpoints = [];
    const isAlreadyAdded = editedEndpoints.some((editedEndpoint) =>
      checkIfSame(selectedEndpoint, editedEndpoint)
    );

    if (isAlreadyAdded) {
      updatedEditedEndpoints = editedEndpoints.map((editedEndpoint) => {
        if (checkIfSame(selectedEndpoint, editedEndpoint)) {
          editedEndpoint = currentEndpoint;
        }
        return editedEndpoint;
      });
    } else {
      updatedEditedEndpoints = editedEndpoints.concat(currentEndpoint);
    }

    setEditedEndpoints(updatedEditedEndpoints);
    setConfirmBox(false);
    setActionsModal(false);
  };

  const handleClose = (e) => {
    clearErrors();
    e.preventDefault();
    setActionsModal(false);
  };

  return (
    <Modal open={actionsModal}>
      <Modal.Header>Endpoint details</Modal.Header>
      <Modal.Content>
        <Form
          data-testid="modalForm"
          onSubmit={handleSubmit(() => setConfirmBox(true))}
          error={!_.isEmpty(errors)}
        >
          <Form.Group>
            {apiType === "rest" && (
              <>
                <Form.Input
                  refs={register("resourcePath", {
                    pattern: {
                      value: /^\/(?!.*v\d)[\w\\/-]+$/,
                      message:
                        "Resource path should start with '/' and only alphanumericals and hyphens are allowed",
                    },
                    validate: (resourcePath) =>
                      !validateResourcePath(resourcePath) ||
                      "Resource path with the same version already exists",
                  })}
                  name="resourcePath"
                  label="Resource path"
                  required
                  readOnly={!(userAction === "create")}
                  placeholder="Enter the resource path"
                  error={
                    errors.resourcePath
                      ? { content: errors.resourcePath.message }
                      : false
                  }
                  onChange={(e, input) => changeResourcePath(input)}
                  defaultValue={
                    selectedEndpoint.resourcePath || selectedEndpoint.action
                  }
                  width={12}
                />
                <Form.Input
                  name="resourceVersion"
                  refs={register("resourceVersion", {
                    pattern: {
                      value: /^v[1-9]$/,
                      message: "Version should be within v1 and v9",
                    },
                    validate: (resourceVersion) =>
                      !validateResourcePath(resourceVersion) ||
                      "Resource version exists",
                  })}
                  label="Resource version"
                  placeholder="Enter the resource version"
                  readOnly={!(userAction === "create")}
                  error={
                    errors.resourceVersion
                      ? { content: errors.resourceVersion.message }
                      : false
                  }
                  onChange={(e, input) => changeResourcePath(input)}
                  defaultValue={selectedEndpoint.resourceVersion || "v1"}
                />
              </>
            )}
            {apiType === "websocket" && (
              <Form.Input
                refs={register("action", {
                  validate: (action) =>
                    !validateAction(action) || "Action already exists",
                })}
                name="action"
                label="Action"
                required
                readOnly={!(userAction === "create")}
                placeholder="Enter the action name"
                error={
                  errors.action ? { content: "Action already exists" } : false
                }
                onChange={(e, input) => changeInput(input)}
                defaultValue={
                  selectedEndpoint.resourcePath || selectedEndpoint.action
                }
                width={12}
              />
            )}
          </Form.Group>
          <Form.Group>
            <Form.Select
              refs={register("integrationType", {
                required: "Please select integration type",
              })}
              name="integrationType"
              label="Integration type"
              required
              placeholder="Select integration type"
              disabled={userAction === "view" || apiType === "websocket"}
              search
              options={convertOptions(
                userAction === "view"
                  ? [selectedEndpoint.integrationType]
                  : dropdownOptions.integrationTypes
              )}
              defaultValue={
                (apiType === "websocket" && "https-private") ||
                selectedEndpoint.integrationType
              }
              error={
                errors.integrationType
                  ? { content: "Please select integration type" }
                  : false
              }
              onChange={(e, input) => changeInput(input)}
            />
            <Form.Select
              refs={register("productCode")}
              name="productCode"
              label="Product code(s)"
              placeholder="Select the product code(s)"
              multiple
              search
              disabled={userAction === "view"}
              options={convertOptions(
                userAction === "view"
                  ? [selectedEndpoint.productCode]
                  : dropdownOptions.productCodes
              )}
              readOnly={userAction === "view"}
              width={12}
              defaultValue={
                selectedEndpoint.productCode &&
                selectedEndpoint.productCode.split(",")
              }
              onChange={(e, input) => changeInput(input)}
            />
          </Form.Group>
          <Header>Backend details</Header>
          <Form.Group data-testid="backend">
            <Form.Input
              refs={register("backendDetails.uri", {
                validate: (uri) =>
                  validateUri(uri) ||
                  `${
                    /lambda/.test(integrationType)
                      ? "Invalid AWS Lambda invocation ARN"
                      : "Invalid URI"
                  }`,
              })}
              name="backendDetails.uri"
              label="URI"
              required
              placeholder="Enter the backend URI"
              readOnly={userAction === "view"}
              width={12}
              defaultValue={selectedEndpoint.backendDetails.uri}
              error={
                errors?.backendDetails?.uri
                  ? {
                      content: errors?.backendDetails?.uri.message,
                    }
                  : false
              }
              onChange={(e, input) => changeInput(input)}
            />
            <Form.Select
              refs={register("backendDetails.httpMethod", {
                required: "Please select HTTP method",
                validate: (httpMethod) =>
                  !validateHttpMethod(httpMethod) ||
                  "HTTP method already exists",
              })}
              name="backendDetails.httpMethod"
              label="HTTP method"
              required
              placeholder="Select HTTP method"
              defaultValue={selectedEndpoint.backendDetails.httpMethod}
              disabled={!(userAction === "create")}
              options={convertOptions(
                userAction === "view"
                  ? [selectedEndpoint.backendDetails.httpMethod]
                  : dropdownOptions.httpMethods
              )}
              error={
                errors?.backendDetails?.httpMethod
                  ? {
                      content: errors?.backendDetails?.httpMethod?.message,
                    }
                  : false
              }
              onChange={(e, input) => handleHttpMethod(input)}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.TextArea
              refs={register("backendDetails.requestPayload", {
                validate: (value) =>
                  validateJSON(value) || "Invalid request payload",
              })}
              name="backendDetails.requestPayload"
              label="Request payload"
              placeholder="Enter the Request payload"
              readOnly={userAction === "view"}
              defaultValue={selectedEndpoint.backendDetails.requestPayload}
              error={
                errors?.backendDetails?.requestPayload
                  ? { content: "Invalid JSON", pointing: "below" }
                  : false
              }
              onChange={(e, input) => changeInput(input)}
            />
            <Form.TextArea
              refs={register("backendDetails.responsePayload", {
                validate: (value) =>
                  validateJSON(value) || "Invalid response payload",
              })}
              name="backendDetails.responsePayload"
              label="Response payload"
              placeholder="Enter the Response payload"
              readOnly={userAction === "view"}
              defaultValue={selectedEndpoint.backendDetails.responsePayload}
              error={
                errors?.backendDetails?.responsePayload
                  ? { content: "Invalid JSON", pointing: "below" }
                  : false
              }
              onChange={(e, input) => changeInput(input)}
            />
          </Form.Group>
          {(integrationType === "lambda" || apiType === "websocket") && (
            <>
              <Header>Request template</Header>
              <Form.Group>
                <Form.Select
                  refs={register("backendDetails.requestTemplate.name", {
                    required: {
                      value:
                        getValues("integrationType") === "lambda" ||
                        apiType === "websocket",
                      message: "Please select the request template name",
                    },
                  })}
                  name="backendDetails.requestTemplate.name"
                  label="Name"
                  required
                  placeholder="Request template name"
                  readOnly={userAction === "view"}
                  options={[
                    // {
                    //   key: 1,
                    //   text: "mock-request-template",
                    //   value: "mock-request-template",
                    // },
                    {
                      key: 1,
                      text: "custom",
                      value: "custom",
                    },
                  ]}
                  error={
                    errors?.backendDetails?.requestTemplate?.name
                      ? {
                          content: "Please select the request template name",
                          pointing: "below",
                        }
                      : false
                  }
                  defaultValue={
                    selectedEndpoint?.backendDetails?.requestTemplate?.name
                  }
                  onChange={(e, input) => changeInput(input)}
                />
                {currentEndpoint?.backendDetails?.requestTemplate?.name ===
                  "custom" && (
                  <Form.TextArea
                    refs={register("backendDetails.requestTemplate.value")}
                    name="backendDetails.requestTemplate.value"
                    label="Value"
                    required
                    placeholder="Enter the Request template"
                    disabled={userAction === "view"}
                    width={12}
                    defaultValue={
                      selectedEndpoint?.backendDetails?.requestTemplate?.value
                    }
                    onChange={(e, input) => changeInput(input)}
                  />
                )}
              </Form.Group>
              {apiType === "rest" && (
                <>
                  <Header>Response template</Header>
                  <Form.Group>
                    <Form.Select
                      refs={register("backendDetails.responseTemplate.name", {
                        required: {
                          value:
                            getValues("integrationType") === "lambda" &&
                            apiType === "rest",
                          message: "Please select the response template name",
                        },
                      })}
                      name="backendDetails.responseTemplate.name"
                      label="Name"
                      required
                      placeholder="Response template name"
                      readOnly={userAction === "view"}
                      options={[
                        // {
                        //   key: 1,
                        //   text: "mock-response-template",
                        //   value: "mock-response-template",
                        // },
                        {
                          key: 1,
                          text: "custom",
                          value: "custom",
                        },
                      ]}
                      error={
                        errors?.backendDetails?.responseTemplate
                          ? {
                              content:
                                "Please select the response template name",
                              pointing: "below",
                            }
                          : false
                      }
                      defaultValue={
                        selectedEndpoint?.backendDetails?.responseTemplate?.name
                      }
                      onChange={(e, input) => changeInput(input)}
                    />
                    {currentEndpoint?.backendDetails?.responseTemplate?.name ===
                      "custom" && (
                      <Form.TextArea
                        refs={register(
                          "backendDetails.responseTemplate.value",
                          {
                            required:
                              "Please select the response template name",
                          }
                        )}
                        name="backendDetails.responseTemplate.value"
                        label="Value"
                        required
                        placeholder="Enter the Response template"
                        disabled={userAction === "view"}
                        width={12}
                        defaultValue={
                          selectedEndpoint?.backendDetails?.responseTemplate
                            ?.value
                        }
                        onChange={(e, input) => changeInput(input)}
                      />
                    )}
                  </Form.Group>
                </>
              )}
            </>
          )}
          <Header>Authorization details</Header>
          <Form.Input
            refs={register("authorizationDetails.scope", {
              pattern: {
                value:
                  /^[a-z0-9]+(-[a-z0-9]+)*$|^[a-z0-9]+(,[a-z0-9]+)*$|^[a-z0-9]+(-[a-z0-9]+)*(,[a-z0-9]+(-[a-z0-9]+)*)*$/,
                message:
                  "Only lowercase alphanumericals and hyphens are allowed. Multiple scopes should be comma separated",
              },
            })}
            name="authorizationDetails.scope"
            label="Scopes"
            required
            placeholder="Enter curity scope"
            readOnly={userAction === "view"}
            defaultValue={selectedEndpoint.authorizationDetails.scope}
            error={
              errors?.authorizationDetails?.scope
                ? {
                    content: errors?.authorizationDetails?.scope?.message,
                    pointing: "below",
                  }
                : false
            }
            onChange={(e, input) => changeInput(input)}
          />
          <Form.TextArea
            refs={register("authorizationDetails.description")}
            name="authorizationDetails.description"
            label="Description"
            required
            placeholder="Enter description for curity scope"
            readOnly={userAction === "view"}
            defaultValue={selectedEndpoint.authorizationDetails.description}
            onChange={(e, input) => changeInput(input)}
          />
          {apiType === "websocket" && (
            <>
              <Header>Version</Header>
              <Form.Group>
                <Form.Input
                  required
                  name="specification.version"
                  label="Version"
                  placeholder="Enter version"
                  defaultValue={
                    selectedEndpoint?.specification?.version || "v1.0.0"
                  }
                  refs={register("specification.version", {
                    required: "Please enter the version",
                    pattern: {
                      value: /^v[1-9].[0-9].[0-9]$/,
                      message: "Version should be v1.0.0,v1.0.1,...",
                    },
                  })}
                  onChange={(e, input) => changeInput(input)}
                  error={
                    errors?.specification?.version
                      ? {
                          content: errors?.specification?.version.message,
                        }
                      : false
                  }
                />
              </Form.Group>
            </>
          )}
          <Message
            error
            header="Please fill all the required fields"
            data-testid="errorMessage"
          />
          <Modal.Actions>
            <Form.Group>
              {userAction !== "view" && (
                <Form.Button
                  disabled={_.isEqual(currentEndpoint, selectedEndpoint)}
                  color="blue"
                >
                  Save changes
                </Form.Button>
              )}
              <Form.Button color="red" onClick={(e) => handleClose(e)}>
                Close
              </Form.Button>
            </Form.Group>
          </Modal.Actions>
        </Form>
      </Modal.Content>
      <Confirm
        open={confirmBox}
        cancelButton="Back"
        content={`Are you sure want to ${userAction} this endpoint?`}
        onConfirm={onSubmit}
        onCancel={() => setConfirmBox(false)}
      />
    </Modal>
  );
}

export default EndPointDetailsForm;
