import React, { useState } from "react";
import { Button, Input, Modal } from "semantic-ui-react";

function DeleteEndpoint({
  confirmDelete,
  setConfirmDelete,
  endpoint,
  setEditedEndpoints,
}) {
  const [input, setInput] = useState("");
  const { resourcePath, resourceVersion, backendDetails, action } = endpoint;
  const httpMethodHelperText = (
    <>
      HTTP method <b>{backendDetails.httpMethod}</b> of the endpoint
    </>
  );

  const submitDeleteEndpoint = (endpoint) => {
    setEditedEndpoints((editedEndpoints) => editedEndpoints.concat(endpoint));
    setConfirmDelete(false);
  };
  return (
    <Modal open={confirmDelete} size="small">
      <Modal.Header>Delete Endpoint</Modal.Header>
      <Modal.Content style={{ textAlign: "center" }}>
        Are you sure you want to delete the{" "}
        {action ? "WebSocket route" : httpMethodHelperText}{" "}
        <b>{resourceVersion + resourcePath || action}</b>
        ?
        <br />
        Type <i>delete</i> in the below text field to confirm deletion.
        <br />
        <Input
          name="fileName"
          placeholder="delete"
          style={{ paddingTop: "1em" }}
          onChange={(e, input) => setInput(input.value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={input.trim() !== "delete"}
          positive
          onClick={() => submitDeleteEndpoint(endpoint)}
        >
          Yes
        </Button>
        <Button negative onClick={() => setConfirmDelete(false)}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default DeleteEndpoint;
